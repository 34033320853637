const ImageLinks = {
	search: require('./icons/search.svg').default,
	search_light_grey: require('./icons/search-light-grey.svg').default,
	cross: require('./icons/cross.svg').default,
	crossBlack: require('./icons/cross-black.svg').default,
	cross_light: require('./icons/cross-light.svg').default,
	location_grey: require('./icons/location-grey.svg').default,
	crossRed: require('./icons/cross-red.svg').default,
	wallet: require('./icons/wallet.svg').default,
	marker: require('./icons/marker.svg').default,
	marker_light_pink: require('./icons/marker-light-pink.svg').default,
	marker2: require('./icons/marker2.svg').default,
	marker_purple: require('./icons/marker-purple.svg').default,
	marker_color_1: require('./icons/marker-color-1.svg').default,
	marker_color_2: require('./icons/marker-color-2.svg').default,
	marker_color_3: require('./icons/marker-color-3.svg').default,
	marker_color_4: require('./icons/marker-color-4.svg').default,
	marker_color_5: require('./icons/marker-color-5.svg').default,
	marker_color_6: require('./icons/marker-color-6.svg').default,
	marker_color_7: require('./icons/marker-color-7.svg').default,
	marker_color_8: require('./icons/marker-color-8.svg').default,
	marker_color_9: require('./icons/marker-color-9.svg').default,
	marker_color_10: require('./icons/marker-color-5.svg').default,
	marker_green: require('./icons/marker-green.svg').default,
	marker_slash: require('./icons/marker-slash.svg').default,
	image_check: require('./image_check.svg').default,
	rider_yellow_circle: require('./rider_yellow_circle.svg').default,
	exclamation_light_red_filled: require('./exclamation_light_red_filled.svg').default,
	exclamation_yellow_filled: require('./exclamation_yellow_filled.svg').default,
	download_filled_purple: require('./download_filled_purple.svg').default,
	marker_down_arrow: require('./icons/marker-down-arrow.svg').default,
	plusGrey: require('./icons/plus-solid-circle-grey.svg').default,
	plusPurple: require('./icons/plus-solid-circle-purple.svg').default,
	packageExample: require('./package-example.svg').default,
	edit: require('./icons/edit.svg').default,
	exclamation_outlined: require('./exclamation_outlined.svg').default,
	edit_white: require('./icons/edit-white.svg').default,
	delete: require('./icons/delete.svg').default,
	left_arrow: require('./icons/left-arrow.svg').default,
	right_arrow: require('./icons/right-arrow.svg').default,
	arrow_up_white: require('./icons/up-arrow.svg').default,
	down_arrow: require('./icons/down-arrow.svg').default,
	arrow_up_circle_solid: require('./icons/arrow-up-purple-solid-circle.svg').default,
	arrow_up: require('./icons/arrow-up.svg').default,
	multi_arrow_up: require('./icons/multi-arrow-up.svg').default,
	multi_arrow_up_white: require('./icons/multi-arrow-up-white.svg').default,
	tickCircleSolid: require('./icons/tick-circle-green-solid.svg').default,
	tickCirclePurpleSolid: require('./icons/tick-circle-purple-solid.svg').default,
	clock: require('./icons/clock.svg').default,
	clock_dark_pink: require('./icons/clock_dark_pink.svg').default,
	box: require('./icons/box.svg').default,
	box2: require('./icons/box2.svg').default,
	box2_purple: require('./icons/box2-purple.svg').default,
	box2_gray: require('./icons/box2_gray.svg').default,
	box2_black: require('./icons/box2_black.svg').default,
	scooter: require('./icons/scooter.svg').default,
	scooter_purple: require('./icons/scooter-purple.svg').default,
	chevronLeft: require('./icons/chevron-left.svg').default,
	chevron_right: require('./icons/chevron-right.svg').default,
	chevron_right_black: require('./icons/chevron-right-black.svg').default,
	chevron_right_red: require('./icons/chevron-right-red.svg').default,
	logo: require('./logo.png'),
	logoFullWhite: require('./logo-full-white.png'),
	zone: require('./icons/zone.svg').default,
	user: require('./icons/user.svg').default,
	phone: require('./icons/phone.svg').default,
	phone_solid_circle: require('./icons/phone-solid-circle.svg').default,
	rider: require('./icons/rider.svg').default,
	rider_purple: require('./icons/rider_purple.svg').default,
	rider_black: require('./icons/rider_black.svg').default,
	rider_light_purple: require('./icons/rider-light-purple.svg').default,
	rider_light_purple2: require('./icons/rider-light-purple2.svg').default,
	rider_light_purple3: require('./icons/rider_light_purple3.svg').default,
	rider_disabled: require('./icons/rider-disabled.svg').default,
	unroute_ripple: require('./unroute_ripple.svg').default,
	unlink_icon: require('./unlink_icon.svg').default,
	unlink_icon_primary: require('./unlink_icon_primary.svg').default,
	unlink_icon_disabled: require('./unlink_icon_disabled.svg').default,
	no_routes_circle: require('./no_routes_circle.svg').default,
	delete_solid_red: require('./icons/delete_solid_red.svg').default,
	purple_circle: require('./icons/purple_circle.svg').default,
	roster: require('./icons/roster.svg').default,
	roster_purple: require('./icons/roster_purple.svg').default,
	hub: require('./icons/hub.svg').default,
	hub_purple: require('./icons/hub_purple.svg').default,
	address: require('./icons/address.svg').default,
	address_purple: require('./icons/address_purple.svg').default,
	track: require('./icons/track.svg').default,
	track_purple: require('./icons/track_purple.svg').default,
	ongoing: require('./icons/ongoing.svg').default,
	ongoing_purple: require('./icons/ongoing_purple.svg').default,
	add: require('./icons/add.svg').default,
	upload: require('./icons/upload.svg').default,
	upload_white: require('./icons/upload_white.svg').default,
	filter: require('./icons/filter.svg').default,
	refresh: require('./icons/refresh.svg').default,
	refresh_single_arrow_purple: require('./icons/refresh_single_arrow_purple.svg').default,
	refresh_single_arrow_grey: require('./icons/refresh_single_arrow_grey.svg').default,
	emptyCheckbox: require('./icons/empty-checkbox.svg').default,
	filledCheckbox: require('./icons/filled-checkbox.svg').default,
	exclamation_error: require('./icons/exclamation-error.svg').default,
	preimum_feature_background: require('./preimum-feature-background.svg').default,
	edit_purple: require('./edit_purple.svg').default,
	xs1: require('./xs-1.svg').default,
	xs2: require('./xs-2.svg').default,
	xs3: require('./xs-3.svg').default,
	small1: require('./small-1.svg').default,
	small2: require('./small-2.svg').default,
	small3: require('./small-3.svg').default,
	medium1: require('./medium-1.svg').default,
	medium2: require('./medium-2.svg').default,
	medium3: require('./medium-3.svg').default,
	large1: require('./large-1.svg').default,
	large2: require('./large-2.svg').default,
	large3: require('./large-3.svg').default,
	xlarge1: require('./x-large-1.svg').default,
	xlarge2: require('./x-large-2.svg').default,
	unroute: require('./unroute.svg').default,
	no_participants: require('./no_participants.svg').default,
	no_riders: require('./no_riders.svg').default,
	invite_empty_state: require('./invite_empty_state.svg').default,
	chevron_right_hollow_purple: require('./icons/chevron-right-hollow-purple.svg').default,
	clock_check: require('./icons/clock-check.svg').default,
	clock_schedule_purple: require('./icons/clock-schedule-purple.svg').default,
	clock_schedule_grey: require('./icons/clock-schedule-grey.svg').default,
	rider_marker: require('./icons/rider-marker.svg').default,
	rider_home: require('./icons/rider-home.svg').default,
	rider_pickup: require('./icons/rider-pickup.svg').default,
	search_light_pink: require('./icons/search-light-pink.svg').default,
	help: require('./icons/help.svg').default,
	filter_pink: require('./icons/filter_pink.svg').default,
	marker_with_check: require('./icons/marker_with_check.svg').default,
	chevron_left_white: require('./icons/chevron-left-white.svg').default,
	cross_filled_purple: require('./icons/cross_filled_purple.svg').default,
	download: require('./icons/download.svg').default,
	inr_filled: require('./icons/inr-filled.svg').default,
	inr_hollow: require('./icons/inr-hollow.svg').default,
	box2_pink: require('./icons/box2-pink.svg').default,
	check_green: require('./icons/check-green.svg').default,
	chevron_right_pink: require('./icons/chevron-right-pink.svg').default,
	arrow_up_pink: require('./icons/arrow_up_pink.svg').default,
	paytm: require('./icons/paytm.svg').default,
	wallet2_purple: require('./icons/wallet2_purple.svg').default,
	wallet2_white: require('./icons/wallet2_white.svg').default,
	plus_solid_circle_green: require('./icons/plus_solid_circle_green.svg').default,
	minus_solid_circle_purple: require('./icons/minus_solid_circle_purple.svg').default,
	calendar_solid_circle: require('./icons/calendar_solid_circle.svg').default,
	pidge_pass_slide_1: require('./pidge_pass_slide_1.svg').default,
	pidge_pass_slide_2: require('./pidge_pass_slide_2.svg').default,
	pidge_pass_slide_3: require('./pidge_pass_slide_3.svg').default,
	arrow_up_green: require('./icons/arrow_up_green.svg').default,
	arrow_down_purple: require('./icons/arrow_down_purple.svg').default,
	face_icon: require('./icons/face-icon.svg').default,
	arrow_icon_white: require('./icons/arrow-icon-white.svg').default,
	yellow_arrow_icon: require('./icons/yellow-arrow-icon.svg').default,
	search_light_purple: require('./icons/search-light-purple.svg').default,
	green_icon: require('./icons/green-icon.svg').default,
	call_filled: require('./icons/call-filled.svg').default,
	bubble1: require('./comm-management/bubble-1.png'),
	bubble2: require('./comm-management/bubble-2.png'),
	filter_black: require('./icons/filter-black.svg').default,
	filter_black_2: require('./icons/filter_black_2.svg').default,
	move_order: require('./move-order-confirm.svg').default,
	light_marker_purple: require('./icons/marker-light-purple.svg').default,
	suitcase: require('./icons/suitcase.svg').default,
	info_circle: require('./icons/info-circle.svg').default,
	location_address: require('./icons/location-address.svg').default,
	order_settings: require('./icons/order-settings.svg').default,
	wallet_black: require('./icons/wallet-black.svg').default,
	double_arrow: require('./icons/double-arrow.svg').default,
	pass: require('./icons/pass.svg').default,
	settings_option: require('./icons/settings-option.svg').default,
	bell: require('./icons/bell.svg').default,
	semi_circles_four: require('./icons/semi-circles-four.svg').default,
	user_white: require('./icons/user_white.svg').default,
	drag_icon: require('./icons/drag-icon.svg').default,
	double_click_icon: require('./icons/double-click-icon.svg').default,
	user_purple: require('./icons/user_purple.svg').default,
	tick_circle_green: require('./icons/tick_circle_green.svg').default,
	tick_circle_white: require('./icons/tick_circle_white.svg').default,
	info_purple: require('./icons/info-purple.svg').default,
	info_light_purple: require('./icons/info-light-purple.svg').default,
	scope: require('./icons/scope.svg').default,
	pause_black_circle: require('./icons/pause_black_circle.svg').default,
	play_black_circle: require('./icons/play_black_circle.svg').default,
	chat_black: require('./icons/chat_black.svg').default,
	box2_black: require('./icons/box2_black.svg').default,
	user_black: require('./icons/user_black.svg').default,
	bag_black: require('./icons/bag_black.svg').default,
	rider_black: require('./icons/rider_black.svg').default,
	cross_circle_solid_gray: require('./icons/cross_circle_solid_gray.svg').default,
	empty_alerts: require('./empty_alerts.svg').default,
	pause_white_circle: require('./icons/pause_white_circle.svg').default,
	play_white_circle: require('./icons/play_white_circle.svg').default,
	arrow_cross_grey: require('./icons/arrow-cross-grey.svg').default,
	arrow_cross_pink: require('./icons/arrow-cross-pink.svg').default,
	cross_marker_grey: require('./icons/cross-marker-grey.svg').default,
	cross_marker_pink: require('./icons/cross-marker-pink.svg').default,
	unschedule_pink: require('./icons/unschedule-pink.svg').default,
	eye_open: require('./icons/eye-open.svg').default,
	eye_close: require('./icons/eye-close.svg').default,
	home: require('./icons/home.svg').default,
	check: require('./icons/check.svg').default,
	clock_black: require('./icons/clock_black.svg').default,
	pidge_box: require('./pidge_box.svg').default,
	refresh_purple: require('./icons/refresh_purple.svg').default,
	chevron_right_grey: require('./icons/chevron-right-grey.svg').default,
	purple_eye: require('./icons/purple_eye.svg').default,
	mobile_otp: require('./icons/mobile_otp.svg').default,
	mobile_capture: require('./icons/mobile_capture.svg').default,
	mobile_signature: require('./icons/mobile_signature.svg').default,
	marker_distance: require('./icons/marker-distance.svg').default,
	wallet2_red: require('./icons/wallet2_red.svg').default,
	delete_pink: require('./icons/delete_pink.svg').default,
	lock: require('./icons/lock.svg').default,
	m500: require('./500m.png'),
	route_z_grey: require('./icons/route-z-grey.svg').default,
	route_z_purple: require('./icons/route-z-purple.svg').default,
	route_z_white: require('./icons/route-z-white.svg').default,
	route_z_light_purple: require('./icons/route-z-light-purple.svg').default,
	track_filled_purple: require('./icons/track_filled_purple.svg').default,
	rider_filled_lifgt_purple: require('./icons/rider_filled_lifgt_purple.svg').default,
	purple_dot_circle: require('./icons/purple-dot-circle.svg').default,
	dash_line: require('./icons/dash-line.svg').default,
	dunzo_logo: require('./dunzo_logo.svg').default,
	chevron_down_gray: require('./icons/chevron_down_gray.svg').default,
	cross_purple: require('./icons/cross-purple.svg').default,
	download_gray: require('./icons/download_gray.svg').default,
	self_fulfilled_logo: require('./self_fulfilled_logo.svg').default,
	user_filled_purple: require('./icons/user_filled_purple.svg').default,
	multi_arrow_up_black: require('./icons/multi-arrow-up-black.svg').default,
	up_down_black: require('./icons/up_down_black.svg').default,
	up_down_purple: require('./icons/up_down_purple.svg').default,
	arrow_up_black: require('./icons/arrow-up-black.svg').default,
	chevron_up_gray: require('./icons/chevron_up_gray.svg').default,
	chevron_up_white: require('./icons/chevron_up_white.svg').default,
	arrow_up_circle_solid_gray: require('./icons/arrow_up_circle_solid_gray.svg').default,
	arrow_up_circle_solid_black: require('./icons/arrow_up_circle_solid_black.svg').default,
	tick_circle_light_purple_solid: require('./icons/tick_circle_light_purple_solid.svg').default,
	delete_red_ripple: require('./icons/delete_red_ripple.svg').default,
	excalamation_yellow_ripple: require('./icons/excalamation_yellow_ripple.svg').default,
	email_graphic: require('./comm-management/email-graphic.svg').default,
	sms_graphic: require('./comm-management/sms-graphic.svg').default,
	tick_circle_light_orange_solid: require('./icons/tick_circle_light_orange_solid.svg').default,
	user_accent: require('./icons/user_accent.svg').default,
	chevron_right_dark_pink: require('./icons/chevron_right_dark_pink.svg').default,
	dashboard: require('./icons/dashboard.svg').default,
	create_order: require('./icons/create-order.svg').default,
	orders: require('./icons/orders.svg').default,
	unschedule: require('./icons/unschedule.svg').default,
	live_tracking: require('./icons/live-tracking.svg').default,
	wallet_recharge: require('./icons/wallet-recharge.svg').default,
	rupee: require('./icons/rupee.svg').default,
	purple_double_circle: require('./icons/purple_double_circle.svg').default,
	cross_filled: require('./icons/cross_filled.svg').default,
	rupee_selected: require('./icons/rupee-selected.svg').default,
	grab_icon_purple: require('./grab_icon_purple.svg').default,
	save_address: require('./icons/save-address.svg').default,
	pidge_mini_logo: require('./icons/pidge-mini-logo.svg').default,
	light_purple_empty_circle: require('./icons/light_purple_empty_circle.svg').default,
	download_circle: require('./icons/download_circle.svg').default,
	logout: require('./icons/logout.svg').default,
	reciept: require('./icons/reciept.svg').default,
	three_dots_light_purple: require('./icons/three_dots_purple.svg').default,
	three_dots_primary: require('./icons/three_dots_primary.svg').default,
	rupee_filled_light_purple: require('./icons/rupee_filled_light_purple.svg').default,
	rupee_filled_gray: require('./icons/rupee_filled_gray.svg').default,
	list_icon: require('./icons/list_icon.svg').default,
	exit_icon: require('./exit_icon.svg').default,
	business_selection: require('./icons/business-selection.svg').default,
	notification_bell: require('./icons/notification-bell.svg').default,
	search_with_light_purple_background: require('./icons/search-with-light-purple-background.svg').default,
	dashboard_selected: require('./icons/dashboard-selected.svg').default,
	create_order_selected: require('./icons/create-order-selected.svg').default,
	orders_selected: require('./icons/orders-selected.svg').default,
	rider_selected: require('./icons/rider-selected.svg').default,
	live_tracking_selected: require('./icons/live-tracking-selected.svg').default,
	live_track: require('./icons/live-track.svg').default,
	save_addresses_selected: require('./icons/save-addresses-selected.svg').default,
	notification_bell_selected: require('./icons/notification-bell-selected.svg').default,
	display_profile_icon: require('./icons/display-profile-icon.svg').default,
	green_tick: require('./icons/green-tick.svg').default,
	arrow_right_light_purple: require('./icons/arrow-right-light-purple.svg').default,
	business_selected_icon: require('./icons/business-selected-icon.svg').default,
	wallet_light_purple: require('./icons/wallet-light-purple.svg').default,
	wallet_selected: require('./icons/wallet-selected.svg').default,
	tracking_light_purple: require('./icons/tracking-light-purple.svg').default,
	tracking_selected: require('./icons/tracking-selected.svg').default,
	settings: require('./icons/settings.svg').default,
	settings_selected: require('./icons/settings-selected.svg').default,
	settings_selected_black: require('./icons/settings-selected-black.svg').default,
	support: require('./icons/support.svg').default,
	support_selected: require('./icons/support-selected.svg').default,
	information_icon: require('./icons/information_icon.svg').default,
	landing_img_1: require('./landing_img_1.png'),
	landing_img_2: require('./landing_img_2.png'),
	landing_img_3: require('./landing_img_3.png'),
	first_time_img1: require('./first_time_img1.svg').default,
	first_time_img2: require('./first_time_img2.svg').default,
	first_time_img3: require('./first_time_img3.svg').default,
	overlay_doc: require('./overlay_doc.svg').default,
	overlay_chat: require('./icons/overlay_chat.svg').default,
	overlay_kyc: require('./overlay_kyc.svg').default,
	box2_filled_purple: require('./icons/box2_filled_purple.svg').default,
	exclamation_purple: require('./icons/exclamation-purple.svg').default,
	integration_icon: require('./icons/integration-icon.svg').default,
	cred_logo: require('./icons/cred-logo.svg').default,
	wix_logo: require('./icons/wix-logo.svg').default,
	dotpe_logo: require('./icons/dotpe-logo.svg').default,
	easyecom_logo: require('./icons/easyecom-logo.svg').default,
	instamojo_logo: require('./icons/instamojo-logo.svg').default,
	magento_logo: require('./icons/magento-logo.svg').default,
	unicommerce_logo: require('./icons/unicommerce-logo.svg').default,
	shopify_logo: require('./icons/shopify-logo.svg').default,
	woocommerce_logo: require('./icons/woo-commerce.svg').default,
	green_tick_ripple: require('./icons/green_tick_ripple.svg').default,
	copy_icon: require('./icons/copy_icon.svg').default,
	document_sample: require('./document-sample.svg').default,
	check_gray: require('./icons/check_gray.svg').default,
	illustration_selected_circle: require('./illustration_selected_circle.svg').default,
	illustration_map: require('./map_big.svg').default,
	map_count_of_points: require('./map_count_of_points.svg').default,
	map_grey_unrouted: require('./map_grey_unrouted.svg').default,
	map_purple_unrouted: require('./map_purple_unrouted.svg').default,
	triangle_marker_purple: require('./triangle_marker_purple.svg').default,
	triangle_marker_white: require('./triangle_marker_white.svg').default,
	edit_purple: require('./icons/edit_purple.svg').default,
	polygon_purple: require('./icons/polygon_purple.svg').default,
	edit_filled_light_purple: require('./icons/edit_filled_light_purple.svg').default,
	upload_light_purple: require('./icons/upload_light_purple.svg').default,
	upload_purple: require('./icons/upload_purple.svg').default,
	eye_gray: require('./icons/eye_gray.svg').default,
	gps_light_purple: require('./icons/gps_light_purple.svg').default,
	zone_black: require('./icons/zone_black.svg').default,
	logout_purple: require('./icons/logout-purple.svg').default,
	speed_meter: require('./icons/speedometer.svg').default,
	pidge_partner_logo: require('./icons/pidge-partner-logo.svg').default,
	dunzo_partner_logo: require('./icons/dunzo-partner-logo.svg').default,
	refresh_dark_purple: require('./icons/refresh-dark-purple.svg').default,
	rider_app_click_tip: require('./rider_app_click_tip.svg').default,
	save_address_light_purple: require('./icons/save-address-light-purple.svg').default,
	tick_circle_green_solid_ripple: require('./icons/tick-circle-green-solid-ripple.svg').default,
	information_reverse: require('./icons/information-reverse.svg').default,
	play_green_circle: require('./icons/play_green_circle.svg').default,
	pause_yellow_circle: require('./icons/pause_yellow_circle.svg').default,
	chat_accent: require('./icons/chat_accent.svg').default,
	bell_light_purple: require('./icons/bell_light_purple.svg').default,
	close_circle_light_pink_solid: require('./icons/close_circle_light_pink_solid.svg').default,
	tick_circle_light_green_solid: require('./icons/tick_circle_light_green_solid.svg').default,
	delete_white: require('./icons/delete_white.svg').default,
	delete_black: require('./icons/delete_black.svg').default,
	pause_yellow_ripple: require('./icons/pause_yellow_ripple.svg').default,
	play_green_ripple: require('./icons/play_green_ripple.svg').default,
	add_black: require('./icons/add_black.svg').default,
	smile_emogi: require('./icons/smile-emogi.svg').default,
	grey_circle_with_line: require('./icons/grey-circle-with-line.svg').default,
	face_emogi: require('./icons/face-emogi.svg').default,
	heart_eye_emogi: require('./icons/heart-eye-emogi.svg').default,
	barcode_scan_no_barcode: require('./barcode_scan_no_barcode.svg').default,
	barcode_scan_refid: require('./barcode_scan_refid.svg').default,
	barcode_scan_pbid: require('./barcode_scan_pbid.svg').default,
	reprior_tips: require('./reprior_tips.svg').default,
	location_pin: require('./location_pin.svg').default,
	rupee_circle_black: require('./rupee_circle_black.svg').default,
	reverse_arrow_purple: require('./icons/reverse_arrow_purple.svg').default,
	alert_triangle: require('./alert_triangle.svg').default,
	thought_bubble: require('./thought_bubble.svg').default,
	home_purple: require('./icons/home_purple.svg').default,
	folder_light_purple: require('./icons/folder-light-purple.svg').default,
	arrow_right_grey: require('./icons/arrow-right-grey.svg').default,
	cricle_with_dashed_light_purple: require('./icons/circle-with-dashed-light-purple.svg').default,
	green_tick_with_light_green_background: require('./icons/green-tick-with-light-green-background.svg').default,
	calender_solid_circle_pink: require('./icons/calender-solid-circle-pink.svg').default,
	information_reverse_red: require('./icons/information-reverse-red.svg').default,
	green_loader: require('./icons/green-loader.svg').default,
	fleet_empty_state: require('./fleet_empty_state.svg').default,
	refresh_double_arrow_white: require('./icons/refresh-double-arrow-white.svg').default,
	upload_light_grey: require('./icons/upload-light-grey.svg').default,
	information_reverse_outline_red: require('./icons/information-reverse-outline-red.svg').default,
	document_scanner: require('./icons/document-scanner.svg').default,
	truck_purple: require('./icons/truck_purple.svg').default,
	truck_white: require('./icons/truck_white.svg').default,
	home_purple: require('./icons/home_purple.svg').default,
	arrow_right_grey: require('./icons/arrow-right-grey.svg').default,
	wefast_logo: require('./wefast_logo.svg').default,
	filled_checkbox_disabled: require('./icons/filled_checkbox_disabled.svg').default,
	bar_chart_purple: require('./icons/bar_chart_purple.svg').default,
	info_filled_purple: require('./icons/info_filled_purple.svg').default,
	info_filled_black: require('./icons/info_filled_black.svg').default,
	pricing_bg: require('./pricing_bg.svg').default,
	tick_circle_purple_solid: require('./icons/tick_circle_purple_solid.svg').default,
	refresh_gray: require('./icons/refresh_gray.svg').default,
	bar_chart_gray: require('./icons/bar_chart_gray.svg').default,
	route_z_black: require('./icons/route-z-black.svg').default,
	lightbulb: require('./lightbulb.svg').default,
	ask_chat: require('./ask_chat.svg').default,
	route_fork_white: require('./icons/route_fork_white.svg').default,
	route_fork_purple: require('./icons/route_fork_purple.svg').default,
	support_chat: require('./icons/support_chat.svg').default,
	help_bulb: require('./icons/help_bulb.svg').default,
	search_outlined: require('./icons/search_outlined.svg').default,
	up_down_arrow_circle_light_purple: require('./icons/up_down_arrow_circle_light_purple.svg').default,
	shadowfax_logo: require('./shadowfax_logo.svg').default,
	loadshare_logo: require('./loadshare_logo.svg').default,
	lock_purple: require('./icons/lock_purple.svg').default,
	polygon_black: require('./icons/polygon_black.svg').default,
	globe_black: require('./icons/globe_black.svg').default,
	globe_purple: require('./icons/globe_purple.svg').default,
	globe_white: require('./icons/globe_white.svg').default,
	rider_white: require('./icons/rider_white.svg').default,
	polygon_white: require('./icons/polygon_white.svg').default,
	add_light_purple: require('./icons/add_light_purple.svg').default,
	tick_circle_white_solid: require('./icons/tick_circle_white_solid.svg').default,
	tick_light_green_solid: require('./icons/tick_light_green_solid.svg').default,
	network_purple: require('./icons/network_purple.svg').default,
	network_light_purple: require('./icons/network_light_purple.svg').default,
	tick_circle_light_pink: require('./icons/tick_circle_light_pink.svg').default,
	excalamation_red_ripple: require('./icons/excalamation_red_ripple.svg').default,
	exclamation_purple_ripple: require('./exclamation_purple_ripple.svg').default,
	globe_gray: require('./icons/globe_gray.svg').default,
	smart_allocation: require('./icons/smart_allocation.svg').default,
	exclamation_red_triangle: require('./exclamation_red_triangle.svg').default,
	flash_white: require('./icons/flash_white.svg').default,
	user_profile: require('./user_profile.svg').default,
	tower_light_purple: require('./icons/tower_light_purple.svg').default,
	exclation_web: require('./icons/exclation_web.svg').default,
	networks_web_light_purple: require('./icons/networks_web_light_purple.svg').default,
	flash_circle_purple: require('./icons/flash_circle_purple.svg').default,
	flash_circle_light_purple: require('./icons/flash_circle_light_purple.svg').default,
	wallet2_orange: require('./icons/wallet2_orange.svg').default,
	wheel: require('./wheel.svg').default,
	finger_purple: require('./finger_purple.svg').default,
	empty_logs: require('./empty_logs.svg').default,
	no_assignment: require('./no_assignment.svg').default,
	delete_red_filled: require('./delete_red_filled.svg').default,
	inr_circle_light_purple: require('./icons/inr_circle_light_purple.svg').default,
	clock_check_light_purple: require('./icons/clock_check_light_purple.svg').default,
	rider_green: require('./icons/rider_green.svg').default,
	zomato_logo: require('./zomato_logo.svg').default,
	porter_logo: require('./porter_logo.svg').default,
	zypp_loog: require('./zypp_logo.png'),
	red_cross_filled_circle: require('./red_cross_filled_circle.svg').default,
	left_right_arrow_purple: require('./icons/left_right_arrow_purple.svg').default,
	left_right_arrow_white: require('./icons/left_right_arrow_white.svg').default,
	left_right_arrow_gray: require('./icons/left_right_arrow_gray.svg').default,
	left_right_arrow_red: require('./icons/left_right_arrow_red.svg').default,
	left_right_filled_arrow_pink: require('./icons/left_right_filled_arrow_pink.svg').default,
	arrow_up_dark_pink: require('./arrow_up_dark_pink.svg').default,
	arrow_down_blue: require('./arrow_down_blue.svg').default,
	hamburger_orange: require('./hamburger_orange.svg').default,
	cross_grey: require('./cross_grey.svg').default,
	mobile_landing: require('./mobile_landing.svg').default,
	empty_trace: require('./empty_trace.svg').default,
	trace_stats_coming_soon: require('./trace_stats_coming_soon.svg').default,
	newspaper_star: require('./newspaper_star.svg').default,
	newspaper_lock: require('./newspaper_lock.svg').default,
	rider_handover_tip: require('./rider_handover_tip.svg').default,
	box_reverse_arrow: require('./box_reverse_arrow.svg').default,
	box_tick: require('./box_tick.svg').default,
	box_rupee: require('./box_rupee.svg').default,
	share_filled: require('./share_filled.svg').default,
	link_chain_filled: require('./link_chain_filled.svg').default,
	green_growth_arrow: require('./green_growth_arrow.svg').default,
	red_downfall_arrow: require('./red_downfall_arrow.svg').default,
	experience_preview_desktop: require('./experience_preview_desktop.svg').default,
	delivery_preview_desktop: require('./delivery_preview_desktop.svg').default,
	map_preview_desktop: require('./map_preview_desktop.svg').default,
	change_rider_icon: require('./change_rider_icon.svg').default,
	partner_preview_desktop_small: require('./partner_preview_desktop_small.svg').default,
	partner_preview_desktop_long: require('./partner_preview_desktop_long.svg').default,
	newspaper_star: require('./newspaper_star.svg').default,
	newspaper_lock: require('./newspaper_lock.svg').default,
	preview_tracking_mobile: require('./preview_tracking_mobile.svg').default,
	live_tracking_light_purple: require('./icons/live_tracking_light_purple.svg').default,
	live_tracking_purple: require('./icons/live_tracking_purple.svg').default,
	alert_triangle_red_filled: require('./alert_triangle_red_filled.svg').default,
	gps_location_solid_light_purple: require('./icons/gps_location_solid_light_purple.svg').default,
	gps_location_solid_purple: require('./icons/gps_location_solid_purple.svg').default,
	rider_yellow: require('./icons/rider_yellow.svg').default,
	tick_circle_solid_gray: require('./icons/tick_circle_solid_gray.svg').default,
	truck_yellow: require('./icons/truck_yellow.svg').default,
	network_pink: require('./icons/network_pink.svg').default,
	delete_solid_light_red: require('./icons/delete_solid_light_red.svg').default,
	manifest_rank: require('./manifest_rank.svg').default,
	cross_circle_solid_red: require('./icons/cross_circle_solid_red.svg').default,
	auto_manifest: require('./icons/auto_manifest.svg').default,
	child_business_empty_state: require('./child_business_empty_state.svg').default,
	create_business: require('./create_business.svg').default,
	info_filled_yellow: require('./icons/info_filled_yellow.svg').default,
	tick_circle_solid_yellow: require('./icons/tick_circle_solid_yellow.svg').default,
	no_rider_icon: require('./icons/no_rider_icon.svg').default,
	empty_scheduled_assignment: require('./empty_scheduled_assignment.svg').default,
	empty_upcoming_assignment: require('./empty_ upcoming_assignment.svg').default,
	alert: require('./icons/alert.svg').default,
	login_background_graphic: require('./login_background_img.svg').default,
	login_graphic_01: require('./login_graphic_01.svg').default,
	login_graphic_02: require('./login_graphic_02.svg').default,
	login_graphic_03: require('./login_graphic_03.svg').default,
	pidge_logo_white: require('./pidge_logo_white.svg').default,
	play_store_logo: require('./play_store_logo.svg').default,
	app_store_logo: require('./app_store_logo.svg').default,
	right_arrow_gray: require('./icons/arrow_right_gray.svg').default,
	arrow_right_white_no_fill: require('./icons/arrow_right_white_no_fill.svg').default,
	addPurple: require('./icons/add_purple.svg').default,
	addGrey: require('./icons/add_gray.svg').default,
	addWhite: require('./icons/add_white.svg').default,
	arrow_right_purple: require('./icons/arrow_right_purple.svg').default,
	cross_white: require('./icons/cross_white.svg').default,
	chevron_right_purple: require('./icons/chevron_right_purple.svg').default,
	download_white: require('./icons/download_white.svg').default,
	add_pruple_no_fill: require('./icons/add_purple_no_fill.svg').default,
	share_puple: require('./icons/share-purple.svg').default,
	empty_state_hub: require('./empty_state_hub.svg').default,
	empty_state_zone: require('./empty_state_zone.svg').default,
	empty_state_roster: require('./empty_state_roster.svg').default,
	empty_state_rider: require('./empty_state_rider.svg').default,
	empty_state_network: require('./empty_state_network.svg').default,
	empty_state_order: require('./empty_state_order.svg').default,
	empty_state_notification_alerts: require('./empty_state_notification_alerts.svg').default,
	empty_state_notification: require('./empty_state_notification.svg').default,
	empty_state_inte_management: require('./empty_state_inte_management.svg').default,
	empty_state_saveaddress: require('./empty_state_saveaddress.svg').default,
	empty_state_teams: require('./empty_state_team.svg').default,
	empty_state_create_order_saveaddress: require('./empty_state_create_order_saveaddress.svg').default,
	upload_black: require('./icons/upload_black.svg').default,
	download_solid_purple: require('./icons/download_solid_purple.svg').default,
	mobile_no_proof: require('./icons/mobile_no_proof.svg').default,
	number_visible: require('./number_visible.svg').default,
	direct_call: require('./direct_call.svg').default,
	call_minute: require('./call_minute.svg').default,
	data_privacy: require('./data_privacy.svg').default,
	call_logging: require('./call_logging.svg').default,
	call_recording: require('./call_recording.svg').default,
	no_result: require('./no_result.svg').default,
	enable_rider_trace: require('./enable_rider_trace.svg').default,
	over_night_circle_purple: require('./icons/over_night_circle_purple.svg').default,
	over_night_lightpurple: require('./icons/over_night_lightpurle.svg').default,
	over_night_purple: require('./icons/over_night_purple.svg').default,
	over_night_circle_lightpurple: require('./icons/over_night_circle_lightpurle.svg').default,
	over_night_filled_purple: require('./icons/over_night_filled_purple.svg').default,
	free_of_cost: require('./free_of_cost.svg').default,
	empty_calculator: require('./empty_calculator.svg').default,
	delete_gray: require('./icons/delete_gray.svg').default,
	change_rider_icon_without_circle: require('./icons/change_rider_icon_without_circle.svg').default,
	change_rider_icon_gray: require('./icons/change_rider_gray.svg').default,
	empty_route: require('./empty_route.svg').default,
	empty_allocate: require('./empty_allocate.svg').default,
	calendar_outlined: require('./icons/calendar_outlined.svg').default,
	login_1: require('./login_1.svg').default,
	login_2: require('./login_2.svg').default,
	login_3: require('./login_3.svg').default,
	login_4: require('./login_4.svg').default,
	login_5: require('./login_5.svg').default,
	login_6: require('./login_6.svg').default,
	undo: require('./undo.svg').default,
	route_z_blue: require('./route_z_blue.svg').default,
	popup_arrow: require('./icons/popup_arrow.svg').default,
	calendar_date: require('./icons/calendar_date.svg').default,
	link_rider: require('./icons/link_rider.svg').default,
	porter_logo_2: require('./porter_logo_2.svg').default,
	zomato_logo_2: require('./zomato_logo_2.svg').default,
	shiprocket_logo: require('./shiprocket_logo.svg').default,
	borzo_logo: require('./borzo_logo.svg').default,
	shadowfax_logo_2: require('./shadowfax_logo_2.svg').default,
	ridding_rangers: require('./ridding_rangers_logo.svg').default,
	truck_delivery_partner: require('./truck_delivery_partner.svg').default,
	road_badg_delivery_partner: require('./road_badg_delivery_partner.svg').default,
	redio_button_active: require('./icons/radio_button_active.svg').default,
	redio_button_inactive: require('./icons/radio_button_inactive.svg').default,
	pause_filled_purple: require('./icons/pause_filled_purple.svg').default,
	pause_filled_gray: require('./icons/pause_filled_gray.svg').default,
	play_filled_purple: require('./icons/play_filled_purple.svg').default,
	notif_tips: require('./notif_tips.svg').default,
	network_undelivered_orders_1: require('./network_undelivered_orders_1.svg').default,
	network_undelivered_orders_2: require('./network_undelivered_orders_2.svg').default,
	network_undelivered_orders_3: require('./network_undelivered_orders_3.svg').default,
	minus_simple_primary: require('./minus_simple_primary.svg').default,
	plus_simple_primary: require('./plus_simple_primary.svg').default,
	percentage_vs_rupees: require('./percentage_vs_rupees.svg').default,
	auto_manifest_no_circle: require('./auto_manifest_no_circle.svg').default,
	empty_invoice: require('./empty_invoice.svg').default,
	chevron_down_solid_blue: require('./icons/chevron_down_solid_blue.svg').default,
	chevron_up_solid_pink: require('./icons/chevron_up_solid_pink.svg').default,
	help_solid_outline_purple: require('./icons/help_solid_outline_purple.svg').default,
	rupee_outlined: require('./icons/rupee_outlined.svg').default,
	smart_allocation_ai: require('./smart_allocation_ai.svg').default,
	smart_allocation_off: require('./smart_allocation_off.svg').default,
	smart_allocation_on: require('./smart_allocation_on.svg').default,
	auto_manifest_fade: require('./icons/auto_manifest_fade.svg').default,
	pidge_titan_white: require('./pidge_titan_white.svg').default,
	pidge_titan_black: require('./pidge_titan_black.svg').default,
	nrai: require('./nrai.svg').default,
	sun_ray: require('./sun_ray.svg').default,
	download_black: require('./icons/download_black.svg').default,
	pidge_titan_explore: require('./pidge_titan_explore.svg').default,
	pidge_titan_activated: require('./pidge_titan_activated.svg').default,
	refresh_double_arrow_green: require('./icons/refresh_double_arrow_green.svg').default,
};

export default ImageLinks;
