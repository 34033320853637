import { Step, StepConnector, StepContent, StepLabel, Stepper, makeStyles, useTheme, withStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Text from 'modules/Text';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import OrderModalContext from '../OrderModalContext';
import moment from 'moment';
import utils from 'resources/utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Routes from 'resources/Routes';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import { shallowEqual, useSelector } from 'react-redux';
import NetworkAvatar from 'modules/NetworkAvatar';
import Button from 'modules/Button';
import constant from 'resources/constant';
import NetworkConfigAvatar from 'modules/NetworkConfigAvatar';

const useStyles = makeStyles((theme) => ({
	container: { backgroundColor: theme.colors.lightGrey7, flex: 0.8, padding: 10, borderRadius: 16, position: 'relative' },
	tracking_btn_wrap: {
		backgroundColor: theme.colors.lightPink4,
		display: 'flex',
		padding: '10px 4px',
		alignItems: 'center',
		borderRadius: 8,
		cursor: 'pointer',
		gap: 5,
	},
	stepper: {
		padding: 0,
		marginTop: 10,
		'&.MuiPaper-root': { backgroundColor: 'transparent' },
		'&.MuiStepConnector-root': { marginLeft: '6px !important' },
	},
	step_content: { marginLeft: 6, marginTop: -3 },
	action_wrap: {
		position: 'absolute',
		bottom: 10,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
	},
	contained_button: { height: 36, borderRadius: 30, width: '90%' },
	negative_action_button: {
		height: 36,
		borderRadius: 30,
		width: '90%',
		backgroundColor: theme.colors.lightRed,
		border: `1px solid ${theme.colors.red} !important`,
	},
	delete_button_wrap: {
		height: 36,
		width: 36,
		backgroundColor: theme.colors.lightRed,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 16,
		marginRight: 5,
		cursor: 'pointer',
	},
	reset_button_wrap: {
		height: 36,
		width: 36,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 16,
		marginRight: 5,
		cursor: 'pointer',
	},
	bundle_label: { color: theme.colors.primary, fontSize: 12 },
	link: {
		textDecoration: 'underline',
		fontSize: 12,
		color: theme.colors.primary,
	},
	unallocate_btn: {
		color: theme.colors.red,
		borderColor: theme.colors.red,
		'&:hover': {
			background: `${theme.colors.lightRed} !important`,
			boxShadow: '0px 4px 20px 0px rgba(102, 102, 102, 0.20) !important',
		},
	},
	scheduled_at: {
		display: 'flex',
		gap: 4,
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		bottom: 70,
		borderRadius: 8,
		background: theme.colors.lightPink,
		padding: 8,
		width: '88%',
	},
}));

const CustomStepConnector = withStyles((theme) => ({
	alternativeLabel: {
		top: 10,
		left: 'calc(-50% + 20px)',
		right: 'calc(50% + 20px)',
	},
	active: {
		'& $line': {
			borderLeft: `1px dashed ${theme.colors.lightPurple6}`,
		},
	},
	completed: {
		'& $line': {
			borderLeft: `1px dashed ${theme.colors.lightPurple6}`,
		},
	},
	disabled: {
		'& $line': {
			borderLeft: `1px dashed ${theme.colors.lightPurple6}`,
		},
	},
	line: {
		borderTopWidth: 3,
		borderTopColor: 'red',
		borderRadius: 1,
	},
	root: {
		marginLeft: 6,
		padding: 0,
	},
}))(StepConnector);

const status_steps = [
	{ title: 'Placed' },
	{ title: 'Fulfillment Cancellations' },
	{ title: 'Manifested' },
	{ title: 'Out for Pickup' },
	{ title: 'Reached Pickup' },
	{ title: 'Picked Up' },
	{ title: 'Out for Delivery' },
	{ title: 'Reached Delivery' },
	{ title: 'Delivery/ Undelivered' },
	{ title: 'RTO Delivered' },
];

function OrderStatusStepper({ parent_api_headers }) {
	const { app } = useSelector((state) => state, shallowEqual);
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const [is_undelivered, set_is_undelivered] = useState(false);
	const [is_rtodelivered, set_is_rtodelivered] = useState(false);
	const [show_history, set_show_history] = useState(false);
	const network_configs = _.get(app, 'app_configs.network_configs', {});

	const {
		data,
		set_show_trip_modal,
		active_step,
		set_active_step,
		handle_cancel_order,
		handle_allocate_click,
		handle_unallocate_order,
		pbid_data,
		handle_unroute_order,
		network_manager_data,
		show_rider_action_save,
		get_network_list,
		show_buttons,
	} = useContext(OrderModalContext);

	let owner_id = utils._retrieveData(constant.OWNER_ID);
	if (!_.isEmpty(parent_api_headers)) owner_id = parent_api_headers['owner-id'];

	const show_unroute =
		data.status === 3 &&
		_.includes(['CREATED', 'OUT_FOR_PICKUP', 'REACHED_PICKUP'], _.get(data, 'fulfillment.status', '-')) &&
		pbid_data.bundle_id != null &&
		((owner_id == pbid_data.business_id && pbid_data.fulfillment_network_id == -2) ||
			(_.includes(network_manager_data, pbid_data.fulfillment_network_id) && pbid_data.supplier_id == null) ||
			owner_id == pbid_data.supplier_id);

	useEffect(() => {
		if (data.status == 0) {
			set_active_step(8);
			return;
		}
		if (!data.fulfillment) {
			set_active_step(0);
			return;
		}
		const _statuses = _.map(data.fulfillment.logs, (logs) => {
			return logs.status;
		});
		if (data.status == 4 || (data.status == 3 && (_.includes(_statuses, 'UNDELIVERED') || _.includes(_statuses, 'RTO_DELIVERED')))) {
			if (_.includes(_statuses, 'UNDELIVERED')) {
				set_is_undelivered(true);
				set_active_step(8);
			}
			if (_.includes(_statuses, 'RTO_DELIVERED')) {
				set_is_undelivered(true);
				set_is_rtodelivered(true);
				set_active_step(8);
			}
			if (_.includes(_statuses, 'DELIVERED')) {
				set_active_step(8);
			}
			return;
		}
		if (_.includes(_statuses, 'REACHED_DELIVERY')) {
			set_active_step(7);
			return;
		}
		if (_.includes(_statuses, 'OUT_FOR_DELIVERY')) {
			set_active_step(6);
			return;
		}
		if (_.includes(_statuses, 'PICKED_UP')) {
			set_active_step(5);
			return;
		}
		if (_.includes(_statuses, 'REACHED_PICKUP')) {
			set_active_step(4);
			return;
		}
		if (_.includes(_statuses, 'OUT_FOR_PICKUP')) {
			set_active_step(3);
			return;
		}
		if (_.includes(_statuses, 'CREATED')) {
			set_active_step(2);
			return;
		}
	}, [data]);
	const show_bundle_label =
		pbid_data.bundle_label &&
		_.includes(['CREATED'], _.get(data, 'fulfillment.status')) &&
		((owner_id == pbid_data.business_id && pbid_data.fulfillment_network_id == '-2') ||
			owner_id == pbid_data.supplier_id ||
			(_.includes(get_network_list, pbid_data.fulfillment_network_id) && pbid_data.supplier_id == null));

	const get_time_stamp = useCallback(
		(index) => {
			if (index == 0) {
				return moment(data.created_at).format('DD-MMM-YY hh:mmA');
			}
			if (!data.fulfillment) {
				return '';
			}
			const _statuses = _.map(data.fulfillment.logs, (logs) => {
				return logs.status;
			});

			if (index == 8 && active_step == 8 && is_undelivered) {
				if (
					!_.isEmpty(
						_.filter(data.fulfillment.logs, (logs) => {
							return logs.status === 'UNDELIVERED';
						})[0],
					)
				) {
					return moment(
						_.filter(data.fulfillment.logs, (logs) => {
							return logs.status === 'UNDELIVERED';
						})[0]?.timestamp,
					).format('DD-MMM-YY hh:mmA');
				}
			}

			if (index == 9 && active_step == 8 && is_rtodelivered) {
				return moment(
					_.filter(data.fulfillment.logs, (logs) => {
						return logs.status === 'RTO_DELIVERED';
					})[0]?.timestamp,
				).format('DD-MMM-YY hh:mmA');
			}

			const required_status =
				index === 1
					? 'CANCELLED'
					: index === 2
					? 'CREATED'
					: index === 3
					? 'OUT_FOR_PICKUP'
					: index === 4
					? 'REACHED_PICKUP'
					: index === 5
					? 'PICKED_UP'
					: index === 6
					? 'OUT_FOR_DELIVERY'
					: index === 7
					? 'REACHED_DELIVERY'
					: 'DELIVERED';

			if (!_.includes(_statuses, required_status)) {
				return '';
			}

			return moment(
				_.filter(data?.fulfillment?.logs, (logs) => {
					return logs.status === required_status;
				})[0]?.timestamp,
			).format('DD-MMM-YY hh:mmA');
		},
		[data, is_undelivered],
	);
	const network_name = _.get(app, `app_configs.network_configs.${data?.planned_fulfillment?.option?.network_id}.name`, '');

	return (
		<div className={classes.container}>
			<div
				className={classes.tracking_btn_wrap}
				onClick={() => {
					if (data.fulfillment) set_show_trip_modal((prev) => !prev);
				}}>
				{!_.isEmpty(network_configs) && network_configs[data?.fulfillment?.channel?.id]?.implementation == 'ppn-private' && data.status != 1 && (
					<NetworkConfigAvatar
						configs={_.get(app, `app_configs.network_configs.${data.fulfillment?.channel?.id}.avatar.configs`, null)}
						height={24}
						width={24}
						image={_.get(app, `app_configs.network_configs.${data.fulfillment?.channel?.id}.avatar.image`, '')}
					/>
				)}
				{!_.isEmpty(network_configs) && (network_configs[data?.fulfillment?.channel?.id]?.implementation != 'ppn-private' || data.status == 1) && (
					<img src={ImageLinks.track_filled_purple} height={24} width={24} style={{ opacity: active_step == 0 ? 0.3 : 1 }} alt='' />
				)}
				<Text semi style={{ fontSize: 12, color: theme.colors.primary, opacity: active_step == 0 ? 0.3 : 1 }}>
					{data.fulfillment?.channel?.id !== '-2' && data.fulfillment?.channel?.id !== '-1' && data.status != 1 ? (
						<>
							{data.fulfillment?.channel?.id == '-3' ? (
								<div>
									<img src={ImageLinks.pidge_titan_black} width={90} />
								</div>
							) : (
								<NetworkConfigAvatar
									name={_.get(data, 'fulfillment.channel.name', '-')}
									logo_width={_.get(data, 'fulfillment.channel.name', '-') === 'captive' ? 100 : 60}
									id={_.get(data, 'fulfillment.channel.id', '')}
								/>
							)}
						</>
					) : (
						<>
							Tracking Details
							<br />
						</>
					)}
					{data.fulfillment ? data.fulfillment.channel.order_id : 'show here'}
				</Text>
			</div>
			<Stepper className={classes.stepper} orientation='vertical' connector={<CustomStepConnector />}>
				{_.map(
					status_steps,
					(step, index) =>
						(data.status != 0 || _.includes([0, 1, 8], index)) && (
							<Step
								key={`status_step_${index}`}
								active={index <= active_step || (index == 9 && active_step == 8 && is_rtodelivered) || index == 1}
								style={{ ...(index == 1 && data.fulfillment_histories?.length == 0 && { marginTop: -25 }) }}>
								{(index != 1 || data.fulfillment_histories?.length > 0) && (
									<StepLabel
										StepIconComponent={() => {
											return (
												<img
													src={
														index == 1
															? ImageLinks.arrow_up_circle_solid_black
															: index <= active_step || (index == 9 && active_step == 8 && is_rtodelivered)
															? index == 8 && (is_undelivered || data.status == 0)
																? ImageLinks.cross_filled
																: ImageLinks.green_tick
															: ImageLinks.light_purple_empty_circle
													}
													height={12}
													width={12}
													style={{ ...(index == 1 && { transform: !show_history ? 'rotate(180deg)' : 'rotate(0deg)' }) }}
													onClick={() => index == 1 && set_show_history((prev) => !prev)}
													alt=''
												/>
											);
										}}>
										<Text
											semi
											style={{
												fontSize: 12,
												color:
													index <= active_step || (index == 9 && active_step == 8 && is_rtodelivered) || index == 1
														? theme.colors.black
														: theme.colors.lightPurple6,
											}}>
											{index == 8 && data.status == 0
												? 'Cancelled'
												: index == 8 && active_step == 8 && is_undelivered
												? 'Undelivered'
												: index == 8 && active_step == 8
												? 'Delivered'
												: index == 9 && active_step == 8 && is_rtodelivered
												? 'RTO Delivered'
												: step.title}
										</Text>
									</StepLabel>
								)}

								<StepContent
									className={classes.step_content}
									style={{ borderLeft: index == 9 ? 'none' : `1px dashed ${theme.colors.lightPurple6}` }}>
									{!_.includes([1, 5], index) && (
										<Text medium style={{ fontSize: 10, marginLeft: -6, color: theme.colors.darkGrey2 }}>
											{get_time_stamp(index)}
										</Text>
									)}
									{show_bundle_label && index == 2 && (
										<div
											className={'d-flex'}
											style={{ gap: 5, cursor: 'pointer' }}
											onClick={() =>
												history.push(`${Routes.GROUPING_AND_ALLOCATION.path}?type=unallocated&bundle_id=${pbid_data.bundle_id}`)
											}>
											<img src={ImageLinks.route_z_purple} alt='' />
											<Text semi className={classes.bundle_label}>
												{pbid_data.bundle_label}
											</Text>
										</div>
									)}
									{index == 1 &&
										show_history &&
										_.map(data?.fulfillment_histories, (history_item, i) => {
											let result;
											if (history_item.logs.length > 2) {
												const created_event = _.find(history_item?.logs, { status: 'CREATED' });
												const cancelled_index = _.findIndex(history_item?.logs, { status: 'CANCELLED' });
												let event_before_cancelled = cancelled_index > 0 ? history_item?.logs[cancelled_index - 1] : null;
												if (event_before_cancelled.status == 'CREATED') {
													event_before_cancelled = cancelled_index > 0 ? history_item?.logs[cancelled_index - 2] : null;
												}
												const cancelled_event = cancelled_index > -1 ? history_item?.logs[cancelled_index] : null;

												result = [created_event, event_before_cancelled, cancelled_event].filter(Boolean);
											} else {
												result = [
													..._.filter(history_item?.logs, (item) => {
														return ['CREATED'].includes(item.status);
													}).slice(0, 1),
													..._.filter(history_item?.logs, (item) => {
														return ['CANCELLED'].includes(item.status);
													}).slice(0, 1),
												];
											}

											return _.map(result, (logs_item, index) => {
												return (
													<BootstrapTooltip
														title={
															<div style={{ padding: 6 }}>
																<div className='d-flex align-items-center' style={{ gap: 5 }}>
																	<img src={ImageLinks.info_filled_black} height={12} width={12} alt='' />
																	<Text semi style={{ fontSize: 12, color: theme.colors.black }}>
																		Details
																	</Text>
																</div>
																<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2, marginTop: 5 }}>
																	Partner: {history_item.channel?.name == 'wefast' ? 'Borzo' : history_item.channel?.name}
																</Text>
																<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2, marginTop: 5 }}>
																	Order ID: {history_item.channel?.order_id}
																</Text>
																{history_item?.cancellation_info?.cancelled_by && logs_item.status == 'CANCELLED' && (
																	<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2, marginTop: 5 }}>
																		Cancelled By:{' '}
																		{history_item?.cancellation_info?.cancelled_by == 1 &&
																		history_item?.cancellation_info?.actor?.type == 1
																			? `User (${history_item?.cancellation_info?.actor?.id})`
																			: history_item?.cancellation_info?.cancelled_by == 1
																			? `API ${
																					history_item?.cancellation_info?.actor
																						? `(${history_item?.cancellation_info?.actor?.id})`
																						: ''
																			  } `
																			: history_item?.cancellation_info?.cancelled_by == 2
																			? 'Partner'
																			: history_item?.cancellation_info?.cancelled_by == 3
																			? 'Alert'
																			: history_item?.cancellation_info?.cancelled_by == 4
																			? 'System'
																			: ''}
																	</Text>
																)}
																{history_item?.cancellation_info?.reason && logs_item.status == 'CANCELLED' && (
																	<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2, marginTop: 5 }}>
																		Reason:{' '}
																		<Text
																			medium
																			component={'span'}
																			style={{
																				textTransform: 'capitalize',
																				fontSize: 10,
																				color: theme.colors.darkGrey2,
																			}}>
																			{history_item?.cancellation_info?.reason}
																		</Text>
																	</Text>
																)}
															</div>
														}
														is_white={true}
														placement='left'>
														<div>
															<StepLabel
																StepIconComponent={() => {
																	return (
																		<div
																			style={{
																				backgroundColor:
																					logs_item.status == 'CANCELLED'
																						? theme.colors.red
																						: theme.colors.lightGreen3,
																				borderRadius: 4,
																				height: 8,
																				width: 8,
																			}}
																			height={12}
																			width={12}
																		/>
																	);
																}}
																style={{ marginLeft: -24, marginTop: 10 }}>
																<Text semi style={{ fontSize: 12, color: theme.colors.black }}>
																	{logs_item.status == 'CANCELLED'
																		? 'Cancelled'
																		: logs_item.status == 'CREATED'
																		? 'Created'
																		: logs_item.status == 'REACHED_PICKUP'
																		? 'Reached Pickup'
																		: logs_item.status == 'OUT_FOR_PICKUP'
																		? 'Out for Pickup'
																		: 'Cancelled'}
																</Text>
															</StepLabel>
															<Text medium style={{ fontSize: 10, marginLeft: -5, color: theme.colors.darkGrey2 }}>
																{moment(logs_item.timestamp).format('DD-MMM-YY hh:mmA')}
															</Text>
														</div>
													</BootstrapTooltip>
												);
											});
										})}

									{index === 3 && (
										<>
											{data.fulfillment?.pickup?.eta && (
												<div style={{ marginLeft: -6 }}>
													<Text semi style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
														Estimated Pickup:
													</Text>
													<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
														{moment(data.fulfillment.pickup.eta).format('DD-MMM-YY hh:mmA')}
													</Text>
												</div>
											)}
											{data?.fulfillment?.pickup?.otp && (
												<div className='d-flex align-items-center' style={{ marginLeft: -6 }}>
													<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
														OTP :
													</Text>
													<Text medium style={{ fontSize: 12, color: theme.colors.primary }}>
														{data?.fulfillment?.pickup?.otp}
													</Text>
												</div>
											)}
										</>
									)}

									{index === 5 && (
										<div style={{ marginLeft: -6 }}>
											<Text semi style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
												Actual Pickup:
											</Text>
											<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
												{get_time_stamp(index)}
											</Text>
											{data.fulfillment?.pickup?.proof?.length > 0 && (
												<div
													className='d-flex align-items-center'
													style={{ gap: 5, cursor: 'pointer' }}
													onClick={() => window.open(data.fulfillment.pickup.proof[0], '_blank')}>
													<img src={ImageLinks.image_check} height={12} width={12} alt='' />
													<Text semi className={classes.link}>
														Pickup
													</Text>
												</div>
											)}
										</div>
									)}
									{index === 6 && (
										<>
											{data.fulfillment?.drop?.eta && (
												<div style={{ marginLeft: -6 }}>
													<Text semi style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
														Estimated Delivery:
													</Text>
													<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
														{moment(data.fulfillment.drop.eta).format('DD-MMM-YY hh:mmA')}
													</Text>
												</div>
											)}
											{data?.fulfillment?.drop?.otp && (
												<div className='d-flex align-items-center' style={{ marginLeft: -6 }}>
													<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
														OTP :
													</Text>
													<Text medium style={{ fontSize: 12, color: theme.colors.primary }}>
														{data?.fulfillment?.drop?.otp}
													</Text>
												</div>
											)}
										</>
									)}

									{index == 8 && data.fulfillment?.drop?.proof?.length > 0 && (
										<div
											className='d-flex align-items-center'
											style={{ gap: 5, cursor: 'pointer', marginLeft: -6 }}
											onClick={() => window.open(data.fulfillment.drop.proof[0], '_blank')}>
											<img src={ImageLinks.image_check} height={12} width={12} alt='' />
											<Text semi className={classes.link}>
												Drop
											</Text>
										</div>
									)}
									{index == 8 && (
										<>
											{_.map(
												[
													..._.filter(data?.fulfillment?.logs, (item) => {
														return ['UNDELIVERED'].includes(item.status);
													}).slice(0, 1),
												],
												(log) => {
													if (log?.remark) {
														return (
															<BootstrapTooltip
																title={
																	<div style={{ padding: 6 }}>
																		<div className='d-flex align-items-center' style={{ gap: 5 }}>
																			<img src={ImageLinks.info_filled_black} height={12} width={12} alt='' />
																			<Text semi style={{ fontSize: 12, color: theme.colors.black }}>
																				Reason
																			</Text>
																		</div>
																		<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2, marginTop: 5 }}>
																			{log?.remark}
																		</Text>
																	</div>
																}
																is_white={true}
																placement='left'>
																<div
																	className='d-flex align-items-center'
																	style={{ gap: 5, cursor: 'pointer', marginLeft: -6 }}>
																	<img src={ImageLinks.image_check} height={12} width={12} alt='' />
																	<Text semi className={classes.link}>
																		Reason
																	</Text>
																</div>
															</BootstrapTooltip>
														);
													}
												},
											)}
										</>
									)}
									{index === 8 && data.status == 0 && (
										<div style={{ marginLeft: -6 }}>
											<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
												{moment(data.updated_at).format('DD-MMM-YY hh:mmA')}
											</Text>
										</div>
									)}
								</StepContent>
							</Step>
						),
				)}
			</Stepper>
			{data.planned_fulfillment && data.status == 1 && (
				<div className={classes.scheduled_at}>
					<div>
						<img src={ImageLinks.clock_dark_pink} height={24} width={24} alt='' />
					</div>
					<div>
						{!network_configs[data.planned_fulfillment.option.network_id]?.avatar.image ? (
							network_configs[data.planned_fulfillment.option.network_id]?.implementation == 'ppn-private' ? (
								<div className='d-flex align-items-center' style={{ gap: 5 }}>
									<div>
										<Text bold>
											{_.get(app.app_configs, `network_configs.${data.planned_fulfillment.option.network_id}.name`, [])} (
											{data.planned_fulfillment.option.network_id})
										</Text>
									</div>
								</div>
							) : (
								<Text style={{ fontSize: 16 }} medium>
									{network_name}
								</Text>
							)
						) : (
							<img src={network_configs[data.planned_fulfillment.option.network_id]?.avatar.image} width={119} height={20} alt='' />
						)}
						<Text semi style={{ fontSize: 12, color: theme.colors.darkPurple }}>
							{data.planned_fulfillment?.scheduled_at ? moment(data.planned_fulfillment?.scheduled_at).format('DD-MMM-YY, hh:mm:A') : '-'}
						</Text>
					</div>
				</div>
			)}
			{!show_rider_action_save && show_buttons && (
				<div className={classes.action_wrap}>
					{data.status === 1 && (
						<div className='d-flex align-items-center'>
							<BootstrapTooltip title={'Cancel Order'}>
								<div className={classes.delete_button_wrap} onClick={() => handle_cancel_order(false)}>
									<img src={ImageLinks.delete} height={16} width={16} alt='' />
								</div>
							</BootstrapTooltip>
							<Button
								type='round'
								size='large'
								text='Allocate'
								onClick={() => handle_allocate_click(false)}
								style={{ width: 115, marginLeft: 4 }}
							/>
						</div>
					)}
					{show_unroute && <Button type='round' text='Unroute' size='large' onClick={() => handle_unroute_order(false)} style={{ width: '90%' }} />}
					{data.status === 3 &&
						!show_unroute &&
						_.includes(['CREATED', 'OUT_FOR_PICKUP', 'REACHED_PICKUP'], _.get(data, 'fulfillment.status', '-')) &&
						owner_id == data.owner.id && (
							<Button
								size='large'
								type='outlined'
								text='Unallocate'
								onClick={() => handle_unallocate_order(false)}
								left_icon={ImageLinks.delete}
								style={{ width: '90%' }}
								className={classes.unallocate_btn}
							/>
						)}
					{data.status === 3 &&
						(data.fulfillment?.channel.id == '-2' || data.fulfillment?.channel.id == '-3') &&
						!pbid_data.bundle_id &&
						_.includes(['CREATED'], _.get(data, 'fulfillment.status', '-')) &&
						((owner_id == pbid_data.business_id && pbid_data.fulfillment_network_id == -2) ||
							(_.includes(network_manager_data, pbid_data.fulfillment_network_id) && pbid_data.supplier_id == null) ||
							owner_id == pbid_data.supplier_id) && (
							<div className='d-flex align-items-center'>
								<Button size='large' type='round' text='Allocate' onClick={() => handle_allocate_click(false)} style={{ width: '90%' }} />
							</div>
						)}
					{/* {data.status === 1 && (
					<OutlinedButton className={classes.negative_action_button}>
						<img src={ImageLinks.unschedule} height={16} width={16} />
						<Text semi style={{ fontSize: 16, color: theme.colors.red }}>
							Unschedule
						</Text>
					</OutlinedButton>
				)} */}
				</div>
			)}
		</div>
	);
}

export default OrderStatusStepper;
